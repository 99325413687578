<script setup>
import { computed, onMounted, ref, watch } from 'vue';
import { useMenuStore } from '@/store/pinia/menu';
import { UseFavoriteFlats } from '@/store/pinia/FavoriteProjects';
import CitizenLogo from '../../UI/CitizenLogo.vue';
import { handleResize } from '@/composables/resizeHandler';
import LangToggler from './UI/LangToggler';
import Navigation from './UI/Navigation';
import { get } from "lodash";
import { useRoute } from "vue-router";
import router from "@/router";
// INIT STORE
const store = useMenuStore();
const storeFlats = UseFavoriteFlats();
let route = useRoute();
let current_lang = computed(() => store?.getLang);
let storeNav = UseFavoriteFlats();

let menu = computed(() => store.indx?.menus?.filter(item => item?.menu_type === 'top')?.map(item => ({
  ...item,
  title: item?.titles?.[current_lang.value]?.title
}))?.sort((a, b) => a.sort - b.sort));
let burger = ref(false);
let headerDelay = ref(false);
let scrolled = ref(false);
let showHeaderRouteSection = ref(false);
let scrollPosition = ref(0);

const openBurger = () => {
  headerDelay.value = true;
  scrolled.value = false;
  showHeaderRouteSection.value = false;
  if (storeFlats.showNav === true) {
    if (scrollPosition.value > 100) scrolled.value = true;
    if (scrollPosition.value > window.innerHeight) showHeaderRouteSection.value = true;

    setTimeout(() => {
      headerDelay.value = false;
    }, 2000);
  }

  storeFlats.setNav(!storeFlats.showNav);
  burger.value = true;
};
watch(() => storeFlats.showNav, () => {
  if (storeFlats.showNav == true) {
    headerDelay.value = true;
    setTimeout(() => {
      headerDelay.value = false;
    }, 2000);
  } else {

    burger.value = !burger.value;
  }
});

const headerBehaviour = () => {
  if (window.innerWidth < 1024) {
    document.querySelector('#app').addEventListener('scroll', e => {
      scrolled.value = e.target.scrollTop > 100;
      scrollPosition.value = e.target.scrollTop;
    });
    return;
  }
  setTimeout(() => {
    store.Loco?.on('scroll', func => {
      if (headerDelay.value) return;
      scrollPosition.value = func.scroll.y;
      scrolled.value = func.scroll.y > 100;
      showHeaderRouteSection.value = func.scroll.y > window.innerHeight;
    });
  }, 1000);
};
const closeBurger = () => {
  storeNav.setNav(false);
};
const changeUrlFromHeader = () => {
  let path = store.indx.widgets?.['explore-cityzen']?.link?.url;

  router.push({
    path: `/${ store.getLang }${ path }`,
    query: route.query
  });
  closeBurger();
};
let noPadding = computed(() => {
  return route.path?.includes('block-') && !route.path?.includes('floor-') && window.innerWidth >= 1024;
});
let isNotProjectPage = computed(() => {
  return !route.path?.includes('projects');
});
const reload = () => {
  if (route.path.includes('home') ||
      route.path.split('/').filter(item => !!item).length === 1)
    location.reload();
};
onMounted(() => {
  headerBehaviour();
});
</script>

<template>
  <!-- :class="{ 'header-scrolled': store.headerScrolled, 'header-opaque': store.isHeaderOpaque }" -->
  <div>
    <header @mouseenter="store.getComponentCursor($event, null)"
            id="header"
            :class="[storeFlats.showNav ? 'active__nav' : '',
            scrolled?'scrolled':'',
            showHeaderRouteSection?'show-route-section':'',
            ]">
      <div :class="{noPadding:noPadding && !scrolled}"
           class="header flex j-btw al-center container">
        <div @click="reload" class="header__logo">
          <router-link to="/" class="flex al-center" :class="{ 'ev-none': store.selected_menu?.url === 'home' }">
            <CitizenLogo/>
          </router-link>
        </div>
        <div class="header__right">
          <div class="header__links flex al-center">
            <router-link v-for="item in menu"
                         @click.native="closeBurger"
                         :to="`/${store.indx.locale}/${item.url_slug}${storeFlats.favoriteFlatIds.length > 0 && item.url_slug === 'favorites' ? '?flats=' + storeFlats.favoriteFlatIds.join() : ''}`"
                         class="pointer roman uppercase link-text f-24 header__links__item">{{ item.title }}
              <span
                  v-if="item.url_slug === 'favorites' && storeFlats.favoriteFlatIds.length > 0">({{
                  storeFlats.favoriteFlatIds.length
                }})</span>
            </router-link>
            <LangToggler class="header__links__item"></LangToggler>

            <div class="burger pointer flex column al-center" :class="burger ? 'active__burger' : ''"
                 @click="openBurger">
              <div class="burger-item"></div>
              <div class="burger-item"></div>
              <div class="burger-item"></div>
            </div>
          </div>
          <div v-if="false" @click="changeUrlFromHeader" class="scroll-route-section">
            <svg width="54" height="55" viewBox="0 0 54 55" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M43.8136 30.1916H0.512169V25.2161H44.3334L22.4743 3.56926L26.027 0.0510149L51.9147 25.6874L53.6911 27.4465L51.9147 29.2056L26.027 54.8419L22.4743 51.3237L43.8136 30.1916Z"
                    fill="black"/>
            </svg>
            <span class="text">CITIZEN</span>
          </div>
        </div>
      </div>
    </header>
    <Navigation :openNav="burger" :menu="menu"/>
  </div>

</template>

<style lang="scss">
.burger-item:nth-child(2) {
  transform-origin: left;
}

.active__burger .burger-item:nth-child(2) {
  transform: scaleX(0);
}

.active__burger .burger-item:nth-child(1) {
  transform: rotate(45deg) translateY(6px) translateX(6.5px);
}

.active__burger .burger-item:nth-child(3) {
  transform: rotate(-45deg) translateY(-5.5px) translateX(4.5px);
}

.header__links {
  transition: 0.6s cubic-bezier(0.79, 0.01, 0.15, 0.99);
}

.header__links.hide {
  opacity: 0;
  pointer-events: none;
}

header.active__nav {
  z-index: 10000;
  background: transparent;
}

.delay {
  transition-delay: 1s !important;
  background: transparent !important;
}

//Laptop
@media (max-width: 1024px) {
  .active__burger .burger-item:nth-child(1) {
    transform: rotate(45deg) translateY(2.5px) translateX(3px);
  }
  .active__burger .burger-item:nth-child(3) {
    transform: rotate(-45deg) translateY(-2.5px) translateX(3px);
  }
}

@media (max-width: 767px) {
  .active__burger .burger-item:nth-child(1) {
    transform: rotate(45deg) translateY(6px) translateX(6.5px);
  }
  .active__burger .burger-item:nth-child(3) {
    transform: rotate(-45deg) translateY(-5.5px) translateX(4.5px);
  }
}

header {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 10px);
  width: 100%;
  z-index: 99;
  height: 102px;
  transition: all 0.4s ease-out;
  background-color: transparent;

  .header__logo {
    cursor: pointer;
  }

  &.scrolled {
    background-color: #857EAB;
    color: white;
    transform: translate(-50%, 0);
    transition: all 0.4s ease-out;

    .header__logo {
      cursor: pointer;

      svg {
        path {
          fill: white !important;
        }
      }
    }

    .header-lang-container {
      span {
        text-shadow: 2px 2px rgba(2, 0, 0, 0.3);
      }
    }

    .link-text, span {
      color: white !important;

      &:before {
        background-color: white !important;
      }
    }

    .burger {
      background-color: white !important;

      .burger-item {
        background: black !important;
      }
    }

  }

  &.show-route-section {
    .scroll-route-section {
      width: 98.26px;
      opacity: 1;
    }

  }

  @include mq(desktop-sm) {
    height: 75px;
  }
  @include mq(tablet) {
    height: 65px;
  }
  @include mq(tablet-sm) {
    height: 55px;
  }

  .header__links {
    a {
      transition: all .45s ease-out;
    }
  }

  &.active__nav {
    .header {
      &.noPadding {
        //svg {
        //  path {
        //    fill: black !important;
        //  }
        //}

        .link-text, span {
          color: black !important;
        }

        .burger-item {
          background: black !important;
        }
      }
    }
  }

  .header {
    z-index: 10;
    height: 100%;
    @include easeInOut(0.5s, all);
    padding: 0 0 0 21px;
    width: 100%;
    margin: 0 auto;
    @media (max-width: 1023px) {
      padding: 0 16px;
    }

    &.noPadding {
      svg {
        path {
          fill: white !important;
        }
      }

      .link-text, span {
        color: white !important;
      }

      .burger-item {
        background: white !important;
      }
    }

    &__logo {
      transition: all .4s ease-out;

      svg {
        width: 433px;
        @include mq(desktop-sm) {
          width: 324px;
        }
        @include mq(tablet) {
          width: 288px;
        }
        @include mq(tablet-sm) {
          width: 173px;
        }
      }
    }

    &__links {
      padding-right: 35px;
      @media only screen and (max-width: 1023px) {
        padding-right: 0;
      }

      a, div {
        position: relative;
        @include mq(desktop-sm) {
          font-size: 21px;
        }
        @include mq(tablet) {
          font-size: 18px;
        }
        @include mq(tablet-sm) {
          font-size: 12px;
        }

        &:not(:last-child) {
          @include hoverLineAnimation(-10px, 1px, calc(100% - 12px));
        }
      }

      &__item {
        margin-right: 20px;
      }

      .burger {
        width: 56px;
        height: 56px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: transparent;
        transition: all .4s ease-out;
        @media only screen and (max-width: 1650px) and (min-width: 1024px) {
          width: 45px;
          height: 45px;
        }
        @media only screen and (max-width: 1023px) and (min-width: 768px) {
          width: 30px;
          height: 30px;
        }
        @media only screen and (max-width: 767px) {
          width: 40px;
          height: 40px;
        }

        .burger-item {
          width: 28px;
          height: 2px;
          margin-right: 0;
          transition: .6s cubic-bezier(0.79, 0.01, 0.15, 0.99);
          background-color: $black;
          @include mq(tablet-sm) {
            width: 11px;
            height: 1px;
          }
          @include mq(mobile) {
            width: 22px;
            height: 2px;
          }

          &:not(:last-child) {
            margin-bottom: 6px;
            @include mq(tablet-sm) {
              margin-bottom: 3px;
            }
            @include mq(mobile) {
              margin-bottom: 6px;
            }
          }

          &:before {
            display: none;
          }
        }
      }

      .uppercase {
        @include mq(mobile) {
          display: none;
        }
      }
    }

    &__right {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 100%;
    }
  }

  &.header-scrolled,
  &.header-opaque {
    background: initial;

    &:before {
      opacity: 0;
      @include easeOut(0.3s, all);
    }

    .header {
      transform: translateY(-27px);

      &__white-overlay {
        width: 100%;
        height: 100%;
        transform: translateY(-57px);
        @include easeOut(0.5s, all);
      }

      &__item h2,
      &__item svg path,
      &__lang span,
      &__lang svg path,
      &__center-col svg path,
      .burger-icon span {
        transition-delay: 250ms;
      }

      &__item {
        position: relative;

        h2 {
          color: $primaryNavy;
          @include easeOut(0.5s, all);
        }

        svg {
          path {
            fill: $burgundy;
            @include easeOut(0.5s, all);

            @include mq(tablet-sm) {
              fill: $primaryNavy;
            }
          }

          circle {
            stroke: $midSilver;
          }
        }

        overflow: hidden;

        &:after {
          content: '';
          position: absolute;
          width: 100%;
          height: 1px;
          bottom: 0px;
          left: 0;
          background-color: $primaryNavy;
          transform: translateX(-100%);
          transition: 0.7s cubic-bezier(0.575, 0.005, 0.285, 1.005) all;
        }

        @include hover {
          &:after {
            transform: translateX(0);
          }
        }

        &-live-wrapper {
          overflow: visible;

          &:after {
            display: none !important;
          }
        }
      }

      &__lang {
        span {
          color: $primaryNavy;
          @include easeOut(0.5s, all);
        }

        svg path {
          stroke: $burgundy;
          @include easeOut(0.5s, all);
        }
      }

      &__center-col {
        transform: translateX(-50%) scale(0.8);
        @include easeOut(0.5s, all);

        // transition-delay: 20ms;
        svg path {
          fill: #25313d;
          @include easeOut(0.5s, all);
        }
      }

      .burger-icon {
        span {
          background-color: $primaryNavy;
          @include easeOut(0.5s, all);
        }
      }
    }

    .el-switch {
      background-color: rgba(41, 54, 82, 0.2) !important;
      border-color: rgba(166, 166, 166, 0.5) !important;

      &__action {
        background-color: $blackBlue !important;
      }

      &__core {
        background-color: transparent !important;
        background: transparent !important;
        border: none !important;

        border-color: initial !important;
      }

      &.is-checked {
        background-color: rgba(41, 54, 82, 0.5) !important;

        .el-switch__core {
          background-color: transparent !important;
          border-color: initial !important;
          background: transparent !important;
          border: none !important;
        }

        .el-switch__action {
          background-color: white !important;
        }
      }
    }

    .header__lang {
      a {
        color: $primaryNavy !important;
      }
    }
  }

  &.header-opaque {
    @include mq(tablet-sm) {
      .header__center-col {
        transform: scale(0.62) translateX(-65%) translateY(-46px) !important;
      }
    }
  }

  @include mq(desktop-sm) {
    .header {
      &__white-overlay {
        transform: translateY(-125px);
      }

      &__center-col {
        svg {
          height: 74px;
          width: 55px;
        }
      }
    }

    &.header-scrolled,
    &.header-opaque {
      .header {
        transform: translateY(0);

        &__center-col {
          transform: translateX(-50%) scale(1);
        }

        &__white-overlay {
          transform: translateY(0px);
        }
      }
    }

    // &.header-opaque {
    //     .header__center-col {

    // transform: scale(0.62) translateX(-65%) translateY(-46px);
    //     }
    // }
  }

  @include mq(tablet) {
    position: fixed;
  }

  @include mq(tablet-sm) {
    &.ov-hidden {
      overflow: visible;
    }

    .header {
      &__item:not(:last-child) {
        margin-right: 8px !important;
      }

      &__white-overlay {
        transform: translateY(-175px);
      }

      &__center-col {
        top: 21px;

        // transform-origin: center ;
        svg {
          height: 120px;
          width: 89px;
        }
      }
    }

    &.header-scrolled {
      // height: 103px;

      .header {
        // &__white-overlay {
        //     // height: 103px;
        // }

        &__center-col {
          transform: scale(0.62) translateX(-65%) translateY(-46px);
        }
      }
    }
  }

  @include mq(mobile) {
    .header {
      &__center {
        svg {
          width: 89px;
          height: 120px;
        }
      }
    }
  }

  .scroll-route-section {
    height: 100%;
    padding: 7px 0;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 0;
    opacity: 0;
    transition: all .45s cubic-bezier(.16, 0, .27, 1);
    cursor: pointer;
    @media only screen and (max-width: 1023px) {
      display: none;
    }

    .text {
      font-weight: 400;
      font-size: 20px;
      color: black !important;
      margin-top: 7px;
      @include mq(desktop-sm) {
        font-size: 18px;
        margin-top: 5px;
      }
    }

    svg {
      @include mq(desktop-sm) {
        width: 35px;
      }

    }
  }
}
</style>

<style lang="scss">
body {
  &.is-dark {
    .header__fav-icon {
      .heart-icon {
        path {
          fill: white;
        }
      }

      &.mobile-heart {
        path {
          // stroke: $burgundy !important;
          stroke: $white;
        }
      }
    }
  }
}
</style>
