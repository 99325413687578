<template>
  <div class="navigation__main" :class="store.showContactSale ? 'navigation__active' : ''">
    <div class="navigation__bg" @click="closePopup"></div>
    <div class="navigation">
      <div class="navigation__layout1 navigation__layout"></div>
      <div class="navigation__layout2 navigation__layout"></div>
      <div class="navigation__layout3 navigation__layout"></div>
      <div class="navigation__layout4 navigation__layout"></div>
      <div class="navigation__content">
        <div class="contract-sales__close" @click="closePopup">
          <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="1.41406" y="6.10352e-05" width="28" height="2" transform="rotate(45 1.41406 6.10352e-05)" fill="black"/>
            <rect x="0.414062" y="19.799" width="28" height="2" transform="rotate(-45 0.414062 19.799)" fill="black"/>
          </svg>
        </div>
        <div class="contract-sales__ttl">{{$fn.tr('Contact Sales')}}</div>

        <div class="contract-sales__inputs">
            <div class="input">
              <input type="text" v-model="Name" :placeholder="$fn.tr('Name')" />
              <div class="error" :class="Name == '' && error ? 'active' : ''">{{$fn.tr('Please fill field')}}</div>
            </div>
            <div class="input">
              <span class="themeSpan">{{$fn.tr('I want to:')}}</span>
             <input
                type="email"
                class="emIn"
                v-model="Option"
                :placeholder="$fn.tr('Buy Apartement, Contact Sales')"
              />
              <div class="error" :class="Option == '' && error ? 'active' : ''">{{$fn.tr('Please fill field')}}</div>
              <i></i>
            </div>
            <div class="input">
              <input type="number" v-model="Email"  :placeholder="$fn.tr('Your number / Email')" />
              <div class="error" :class="Email == '' && error ? 'active' : ''">{{$fn.tr('Please fill field')}}</div>
              <i></i>
            </div>
            <div class="input">
              <input
                type="textarea"
                v-model="Message"
                :placeholder="$fn.tr('Write a Message')"
                @keyup.enter="sendReq"
              />
              <i></i>
            </div>
        </div>
        <div class="contract-sales__bottom">
           <div class="contract-sales__send linkHov" @click="sendReq">
             {{$fn.tr('Send')}}
          </div>
         <div class="contract-sales__sub">
           {{$fn.tr('You may as well directly reach us with call')}} <br/><a :href="'tel:' + $fn.tr('MobileNumber')">{{$fn.tr('MobileNumber')}}</a>
        </div>
        <div class="contract-sales__sub" v-if="false">
          {{$fn.tr('Need help? ')}}<a href="##">{{$fn.tr('Contact Us')}}</a>
        </div>
        </div>
        <div class="done" :class="done ? 'active' : ''">
          {{$fn.tr('WELL DONE!')}}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {computed, nextTick, onMounted, ref, watch} from "vue";
import {UseFavoriteFlats} from '@/store/pinia/FavoriteProjects';
import {useRouter,useRoute} from "vue-router";
let route = useRoute()
import fn from "@/helpers/func";
const Name = ref("");
const Email = ref("");
const Message = ref("");
const Option = ref("");
const done = ref(false);
const error = ref(false);


let store = UseFavoriteFlats();

let props = defineProps(
    {
      openNav : {
        required: false,
      }
    }
);
let closePopup = function () {
  store.setContactSale(false);
  console.log(store.showContactSale)
}

watch(Email, () => {
  if(Email.value < 0) {
    Email.value = 0
  }
});

async function sendReq() {
  if (Name.value !== '' && Email.value !== '' && Option.value !== ''){
    const formData = {
      name: Name.value,
      email: Email.value,
      message: Message.value,
      option: Option.value,
      url: window.location.href,
      formType: "contractSale",
    };
    let tmp = await fn.postData("main/saveSubmitedForm", formData);
    if (tmp.status == 200) {
      console.log(tmp);
      Name.value = "";
      Email.value = "";
      Message.value = "";
      Option.value = "";
      done.value = true;
      error.value = false;
      setTimeout(() => {
        done.value = false;
        setTimeout(() => {
          closePopup()
        }, 1000)
      }, 2000)
    }
    return true;
  } else {
    error.value = true
  }
}
</script>
<style lang="scss" scoped>
.themeSpan {
  position: absolute;
  display: flex;
  left: 0px;
  height: 90px;
  background: #fff;
  bottom: 31px;
  align-items: center;
  font-size: 34px;
  line-height: 41px;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
  .navigation__main {
    position: fixed;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    z-index: 100000;
    pointer-events: none;
  }
  .navigation__active.navigation__main {
    pointer-events: all;
  }
  .navigation__bg {
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: rgba(0,0,0,.5);
    transition: .6s cubic-bezier(0.79, 0.01, 0.15, 0.99);
    opacity: 0;
    transition-delay: 1.2s;
  }
  .navigation__active .navigation__bg {
    opacity: 1;
    transition-delay: 0s;
  }
  .navigation {
    width: 665px;
    height: 100%;
    position: absolute;
    right: 40px;
    top: 0px;
    z-index: 5;
  }
  .navigation__layout {
    position: absolute;
    z-index: 1;
    background: #fff;
    transition: .6s cubic-bezier(0.79, 0.01, 0.15, 0.99);
  }
  .navigation__layout1 {
    width: 100%;
    top: 0px;
    height: 100%;
    left: 0px;
    transform: scaleY(0);
    transform-origin: top;
    transition-delay: .8s;
  }
  .navigation__layout2 {
    width: 40px;
    top: 20px;
    height: calc(100% - 220px);
    left: -40px;
    transform: scaleX(0);
    transform-origin: right;
    transition-delay: .4s;
  }
  .navigation__layout3 {
    width: 20px;
    top: 0px;
    height: calc(100% - 100px);
    right: -20px;
    transform: scaleX(0);
    transform-origin: left;
    transition-delay: .3s;
  }
  .navigation__layout4 {
    width: 20px;
    top: 0px;
    height: calc(100% - 120px);
    right: -40px;
    transform: scaleX(0);
    transform-origin: left;
    transition-delay: 0s;
  }
  .navigation__active .navigation__layout1 {
    transition-delay: .2s;
  }
  .navigation__active .navigation__layout2 {
    transition-delay: .7s;
  }
  .navigation__active .navigation__layout3 {
    transition-delay: .8s;
  }
  .navigation__active .navigation__layout4 {
    transition-delay: 1.1s;
  }
  .navigation__active .navigation__layout{
    transform: scaleX(1) scaleY(1);
  }
  .navigation__content {
    position: absolute;
    z-index: 10;
    width: 100%;
    top: 0px;
    height: 100%;
    left: 0px;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0px 50px;
    padding-bottom: 30px;
  }
  .navigation__content-inn {
    display: flex;
    flex-direction: column;
  }
  .navigation__content-inn a {
    overflow: hidden;
    font-size: 74px;
    line-height: 90px;
    margin: 50px 0px;
    display: flex;
    padding: 7px 0px;
    justify-content: center;
  }
  .navigation__content-inn a span {
    font-size: inherit;
    line-height: inherit;
    transition: .6s cubic-bezier(0.79, 0.01, 0.15, 0.99);
    transform: translateY(110%);
    position: relative;
  }
  .done {
    transition: .6s cubic-bezier(0.79, 0.01, 0.15, 0.99);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 52px;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 5;
    height: 100%;
    width: 100%;
    background: #fff;
  }
  .done.active{
    opacity: 1;
  }
  .navigation__content-inn a span:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 0px;
    width: 100%;
    height: 3px;
    background: #000000;
    transition: .6s cubic-bezier(0.79, 0.01, 0.15, 0.99);
    transform: scaleX(0);
    transform-origin: left;
  }
  .linkHov {
    position: relative;
  }
  .linkHov:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 0px;
    width: 100%;
    height: 2px;
    background: #000000;
    transition: .6s cubic-bezier(0.79, 0.01, 0.15, 0.99);
    transform: scaleX(0);
    transform-origin: left;
  }
  .linkHov:hover:after {
    transform: scaleX(1);
  }
  .navigation__content-inn a.active span:after,.navigation__content-inn a:hover span:after {
    transform: scaleX(1);
  }
  .navigation__content-inn a:nth-child(1) span {
    transition-delay: 0s;
  }
  .navigation__content-inn a:nth-child(2) span {
    transition-delay: .1s;
  }
  .navigation__content-inn a:nth-child(3) span {
    transition-delay: .2s;
  }
  .navigation__content-inn a:nth-child(4) span {
    transition-delay: .3s;
  }
  .navigation__active .navigation__content-inn a:nth-child(1) span {
    transition-delay: .3s;
  }
  .navigation__active .navigation__content-inn a:nth-child(2) span {
    transition-delay: .4s;
  }
  .navigation__active .navigation__content-inn a:nth-child(3) span {
    transition-delay: .5s;
  }
  .navigation__active .navigation__content-inn a:nth-child(4) span {
    transition-delay: .6s;
  }
  .navigation__active .navigation__content-inn a span{
    transform: translateY(0%);
  }
  .navigation__socs {
    position: absolute;
    left: 0px;
    width: 100%;
    display: flex;
    bottom: 60px;
    justify-content: center;
    padding: 5px 0px;
    overflow: hidden;
  }
  .navigation__socs a {
    margin: 0px 10px;
  }

  .navigation__socsInner {
    display: flex;
    transform: translateY(120%);
    transition: .6s cubic-bezier(0.79, 0.01, 0.15, 0.99);
    transition-delay: .4s;
  }
  .navigation__active .navigation__socsInner {
    transition-delay: .7s;
    transform: translateY(0%);
  }
  .contract-sales__ttl {
    margin-top: 130px;
    font-size: 36px;
    line-height: 40px;
    margin-bottom: 50px;
    transform: translateY(10px);
    transition: .6s cubic-bezier(0.79, 0.01, 0.15, 0.99);
    transition-delay: 0s;
    opacity: 0;
  }
  .navigation__active .contract-sales__ttl {
    opacity: 1;
    transform: translateY(0px);
    transition-delay: .4s;
  }
  .contract-sales__inputs {
    display:flex;
    flex-direction: column;
    width: 100%;
    transform: translateY(10px);
    transition: .6s cubic-bezier(0.79, 0.01, 0.15, 0.99);
    transition-delay: .1s;
    opacity: 0;
  }
  .navigation__active .contract-sales__inputs {
    transition-delay: .5s;
    opacity: 1;
    transform: translateY(0px);
  }
  .contract-sales__bottom {
    width: 100%;
    display:flex;
    flex-direction: column;
    height: 100%;
    transform: translateY(10px);
    transition: .6s cubic-bezier(0.79, 0.01, 0.15, 0.99);
    transition-delay: .2s;
    opacity: 0;
  }
  .navigation__active .contract-sales__bottom {
    transition-delay: .6s;
    opacity: 1;
    transform: translateY(0px);
  }
  .contract-sales__inputs input {
    font-size: 34px;
    line-height: 41px;
    height: 90px;
    border: none;
    border-bottom: 1px solid #000000;
    margin-bottom: 30px;
    width: 100%;
  }
  .emIn {
    padding-left: 130px;
  }
  .contract-sales__inputs input::placeholder{
    color: #9C9C9C;
  }
  .contract-sales__send {
    display: flex;
    margin: 40px auto;
    font-size: 34px;
    line-height: 41px;
    text-transform: uppercase;
    cursor: pointer;
    margin-bottom: 0px;
  }
  .contract-sales__sub {
    font-size: 28px;
    line-height: 34px;
    text-align: left;
    margin-right: auto;
    margin-bottom: 30px;
    margin-top: 34px;
  }
  .contract-sales__sub a {
    transition: .6s cubic-bezier(0.79, 0.01, 0.15, 0.99);
    border-bottom: 1px solid #000;
  }
  .contract-sales__sub a:hover {
    border-color: rgba(0,0,0,0);
  }
  .contract-sales__close {
    position: absolute;
    top: 40px;
    right: 0px;
    cursor: pointer;
    transition: .6s cubic-bezier(0.79, 0.01, 0.15, 0.99);
    opacity: 0;
    transform: scale(.9);
    z-index: 10;
  }
  .navigation__active .contract-sales__close{
    opacity: 1;
    transform: scale(1);
  }

  .contract-sales__close:hover {
    opacity: .7;
  }
  .input{
    position: relative;
    width: 100%;
  }
  .error {
    position: absolute;
    left: 0px;
    bottom: -5px;
    font-size: 12px;
    z-index: 3;
    color: #dd0d0d;
    transition: 950ms cubic-bezier(0.77, 0, 0.175, 1);
    opacity: 0;
    pointer-events: none;
    &.active{
      opacity: 1;
    }
  }
  //Laptop
  @media (max-width: 1900px) {
    .themeSpan {
      height: 40px;
      font-size: 21px;
      line-height: 34px;
      bottom: 27px;
    }
    .emIn{
      padding-left: 80px;
    }
    .navigation__content {
      padding-bottom: 10px;
    }
    .contract-sales__sub {
      font-size: 20px;
      line-height: 24px;
    }
    .contract-sales__send {
      font-size: 28px;
    }
    .contract-sales__inputs input {
      font-size: 21px;
      line-height: 34px;
      height: 60px;
      margin-bottom: 15px;
      width: 100%;
    }
    .contract-sales__ttl {
      font-size: 28px;
      line-height: 34px;
      margin-top: 36px;
    }
    .navigation {
      width: 480px;
    }
    .navigation__layout2 {
      width: 20px;
      top: 20px;
      left: -20px;
      height: calc(100% - 120px);
    }
    .navigation__layout3 {
      width: 10px;
      height: calc(100% - 50px);
      right: -10px;
    }
    .navigation__layout4 {
      width: 10px;
      height: calc(100% - 70px);
      right: -20px;
    }
    .navigation__content-inn a {
      font-size: 60px;
      line-height: 70px;
      margin: 20px 0px
    }
    .navigation__socs a {
      font-size: 18px;
    }
    .navigation__socs {
      bottom: 40px
    }
    .navigation {
      right: 20px;
    }
  }
  //Ipad
  @media (max-width: 1279px) {
    .navigation__content-inn a {
      font-size: 34px;
      line-height: 42px;
    }
  }
  //Mobile
  @media (max-width: 767px) {
    .contract-sales__ttl {
      font-size: 23px;
      line-height: 29px;
    }
    .navigation {
      width: 100%;
      right: 0px;
    }
    .navigation__content {
      padding: 0px 16px;
    }
    .contract-sales__ttl {
      margin-right: auto;
    }
    .contract-sales__close {
      right: 16px;
    }
    .contract-sales__send {
      margin-top: 15px;
    }
    .contract-sales__sub {
      font-size: 18px;
      line-height: 22px;
    }
  }
</style>
