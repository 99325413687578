import {defineStore} from "pinia";

export const useMap = defineStore("map", {
    state: () => ({
        _show: false,
        _url: ''
    }), getters: {
        showMapPopup(state) {
            return state._show;
        },
        url(state) {
            return state._url
        }
    }, actions: {
        setMapPopup(value) {
            this._show = value;
        },
        setUrl(value) {
            this._url = value
        }
    },
});
