<script setup>
import {useGallery} from "@/store/pinia/gallery";
import {useMenuStore} from "@/store/pinia/menu";
import {computed, nextTick, onMounted} from "vue";
import {ref} from "vue";
import Swiper from "swiper";

let menuStore = useMenuStore()
let store = useGallery()

const gallery = computed(() => store.galleryItems)
let swiper = ref(null)
let setSwiper = () => {
  swiper.value = new Swiper("#pop-up-gallery", {
    pagination: {
      el: ".swiper-pagination",
    },
    speed: 1000
  });

}

const slide = (index) => {
  let sw = Array.isArray(swiper.value) ? swiper.value?.[1] : swiper.value
  if (index === 1) sw.slideNext()
  else sw.slidePrev()
}
const close = () => {
  store.setGalleryItems(null)
}
onMounted(async () => {
  await nextTick()
  setSwiper()
})
</script>

<template>
  <div class="image-gallery-pop-up">
    <div id="pop-up-gallery" class="swiper ">
      <div class="swiper-wrapper">
        <div v-for="item in gallery" class="swiper-slide">
          <img :src="item.url" class="image" alt="">
        </div>
      </div>
      <div
          @mouseenter="menuStore.getComponentCursor($event, 'left')"
          @mouseleave="menuStore.getComponentCursor($event, null)"
          @click="slide(-1)"
          class="left-cursor cursor-nav"></div>
      <div
          @mouseenter="menuStore.getComponentCursor($event, 'right')"
          @mouseout="menuStore.getComponentCursor($event, null)"
          @click="slide(1)"
          class="right-cursor cursor-nav"></div>
      <div
          @mouseenter="menuStore.getComponentCursor($event, 'zoom-cancel')"
          @mouseleave="menuStore.getComponentCursor($event, null)"
          @mouseup="menuStore.getComponentCursor($event, null)"
          @click="close"
          class="horizontal-nav up"></div>
      <div
          @mouseenter="menuStore.getComponentCursor($event, 'zoom-cancel')"
          @mouseleave="menuStore.getComponentCursor($event, null)"
          @mouseup="menuStore.getComponentCursor($event, null)"
          @click="close"
          class="horizontal-nav bottom"></div>
    </div>

  </div>
</template>

<style lang="scss" scoped>
.image-gallery-pop-up {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background: #1E1E1E;
  z-index: 99;

  .swiper {
    width: 100%;
    height: 100vh;

    .swiper-wrapper {
      transition-timing-function: cubic-bezier(.23, -0.01, .21, .99);
      .swiper-slide{
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }

  .horizontal-nav {
    position: absolute;
    width: 100%;
    height: 20vh;
    background: transparent;
    z-index: 1;

    &.up {
      top: 0;
    }

    &.bottom {
      bottom: 0;
    }
  }

  .cursor-nav {
    position: absolute;
    width: 50%;
    height: 100%;
    top: 0;
    background: transparent;
    z-index: 1;

    &.left-cursor {
      left: 0;
    }

    &.right-cursor {
      right: 0;
    }
  }

}
</style>
