<template>
  <div class="navigation__main" :class="storeNav.showNav ? 'navigation__active' : ''">
    <div class="navigation__bg" @click="closeBurger"></div>
    <div class="navigation">
      <div class="navigation__layout1 navigation__layout"></div>
      <div class="navigation__layout2 navigation__layout"></div>
      <div class="navigation__layout3 navigation__layout"></div>
      <div class="navigation__layout4 navigation__layout"></div>
      <div class="navigation__content">
        <LangTooglerMobile v-if="true"/>
        <div class="navigation__content-inn">
          <template v-for="(item, index) in $get(store.indx, 'menus').sort((a, b) => a.sort - b.sort)" :key="index">
            <a v-if="!item.configs.includes('hide_from_menu') && item.menu_type == 'main_menu'"
               @click="changeRoute(item)">
              <span v-if=" item.titles[store.indx.locale]">{{ item.titles[store.indx.locale].title }}</span>
            </a>
          </template>

        </div>
        <div class="navigation__socs">
          <div class="navigation__socsInner">
            <template v-for="menuItem in menu">
              <router-link @click.native="closeBurger" :to="'/' + menuItem.url_slug"
                           v-if="hideFromMobile(menuItem)"
                           class="linkHov hideDesk">{{ menuItem.title }}
              </router-link>
            </template>

            <div class="soc-wrapper">
              <a :href="$get(store.indx, 'widgets.footer_social_links.fb.url')"
                 :target="$get(store.indx, 'widgets.footer_social_links.fb.targetblank') ? '_blank' : 'self'"
                 class="linkHov">
                <svg width="43" height="42" viewBox="0 0 43 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M15.4535 21.7172H18.0575V31.6452C18.0575 31.8412 18.229 32 18.4406 32H22.8558C23.0674 32 23.2389 31.8412 23.2389 31.6452V21.764H26.2324C26.4271 21.764 26.5908 21.6287 26.6131 21.4497L27.0677 17.7947C27.0802 17.6941 27.0458 17.5934 26.9731 17.518C26.9004 17.4425 26.7964 17.3992 26.6872 17.3992H23.2391V15.1081C23.2391 14.4175 23.6406 14.0672 24.4327 14.0672C24.5456 14.0672 26.6872 14.0672 26.6872 14.0672C26.8988 14.0672 27.0703 13.9083 27.0703 13.7124V10.3575C27.0703 10.1615 26.8988 10.0026 26.6872 10.0026H23.5802C23.5582 10.0016 23.5096 10 23.4379 10C22.8988 10 21.0249 10.098 19.5447 11.3591C17.9047 12.7566 18.1326 14.4299 18.1871 14.72V17.3992H15.4535C15.2418 17.3992 15.0703 17.558 15.0703 17.754V21.3623C15.0703 21.5583 15.2418 21.7172 15.4535 21.7172Z"
                      fill="black"/>
                </svg>
              </a>
              <a :href="$get(store.indx, 'widgets.footer_social_links.instagram.url')"
                 :target="$get(store.indx, 'widgets.footer_social_links.instagram.targetblank') ? '_blank' : 'self'"
                 class="linkHov">
                <svg width="43" height="42" viewBox="0 0 43 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M15.3771 7.44482H26.7635C31.1011 7.44482 34.6255 10.9692 34.6255 15.3068V26.6932C34.6255 28.7783 33.7972 30.778 32.3228 32.2525C30.8484 33.7269 28.8486 34.5552 26.7635 34.5552H15.3771C11.0395 34.5552 7.51514 31.0308 7.51514 26.6932V15.3068C7.51514 13.2217 8.34345 11.222 9.81786 9.74755C11.2923 8.27314 13.292 7.44482 15.3771 7.44482ZM15.106 10.1559C13.8118 10.1559 12.5706 10.67 11.6555 11.5851C10.7403 12.5003 10.2262 13.7415 10.2262 15.0357V26.9643C10.2262 29.6618 12.4086 31.8441 15.106 31.8441H27.0346C28.3288 31.8441 29.57 31.33 30.4852 30.4149C31.4003 29.4997 31.9145 28.2585 31.9145 26.9643V15.0357C31.9145 12.3382 29.7321 10.1559 27.0346 10.1559H15.106ZM28.1868 12.1891C28.6362 12.1891 29.0671 12.3677 29.3849 12.6854C29.7027 13.0032 29.8812 13.4342 29.8812 13.8835C29.8812 14.3329 29.7027 14.7639 29.3849 15.0817C29.0671 15.3994 28.6362 15.5779 28.1868 15.5779C27.7374 15.5779 27.3064 15.3994 26.9887 15.0817C26.6709 14.7639 26.4924 14.3329 26.4924 13.8835C26.4924 13.4342 26.6709 13.0032 26.9887 12.6854C27.3064 12.3677 27.7374 12.1891 28.1868 12.1891ZM21.0703 14.2224C22.8678 14.2224 24.5917 14.9365 25.8628 16.2075C27.1338 17.4786 27.8479 19.2025 27.8479 21C27.8479 22.7975 27.1338 24.5214 25.8628 25.7925C24.5917 27.0635 22.8678 27.7776 21.0703 27.7776C19.2728 27.7776 17.5489 27.0635 16.2778 25.7925C15.0068 24.5214 14.2927 22.7975 14.2927 21C14.2927 19.2025 15.0068 17.4786 16.2778 16.2075C17.5489 14.9365 19.2728 14.2224 21.0703 14.2224ZM21.0703 16.9334C19.9918 16.9334 18.9575 17.3619 18.1948 18.1245C17.4322 18.8871 17.0038 19.9215 17.0038 21C17.0038 22.0785 17.4322 23.1129 18.1948 23.8755C18.9575 24.6381 19.9918 25.0666 21.0703 25.0666C22.1488 25.0666 23.1832 24.6381 23.9458 23.8755C24.7084 23.1129 25.1369 22.0785 25.1369 21C25.1369 19.9215 24.7084 18.8871 23.9458 18.1245C23.1832 17.3619 22.1488 16.9334 21.0703 16.9334Z"
                      fill="black"/>
                </svg>
              </a>
              <a :href="'mailto:' + $get(store.indx, 'widgets.footer_social_links.mail.url')"
                 :target="$get(store.indx, 'widgets.footer_social_links.mail.targetblank') ? '_blank' : 'self'"
                 class="linkHov">
                <svg width="43" height="42" viewBox="0 0 43 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M9.47031 32.5C8.67281 32.5 7.99035 32.2187 7.42291 31.6562C6.85451 31.0927 6.57031 30.4156 6.57031 29.625V12.375C6.57031 11.5844 6.85451 10.9078 7.42291 10.3452C7.99035 9.78175 8.67281 9.5 9.47031 9.5H32.6703C33.4678 9.5 34.1508 9.78175 34.7192 10.3452C35.2866 10.9078 35.5703 11.5844 35.5703 12.375V29.625C35.5703 30.4156 35.2866 31.0927 34.7192 31.6562C34.1508 32.2187 33.4678 32.5 32.6703 32.5H9.47031ZM21.0703 22.4375L9.47031 15.25V29.625H32.6703V15.25L21.0703 22.4375ZM21.0703 19.5625L32.6703 12.375H9.47031L21.0703 19.5625ZM9.47031 15.25V12.375V29.625V15.25Z"
                      fill="black"/>
                </svg>
              </a>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, nextTick, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from 'vue-router';
import { useMenuStore } from "@/store/pinia/menu/index";
import { UseFavoriteFlats } from '@/store/pinia/FavoriteProjects';
import LangTooglerMobile from './LangTooglerMobile';

let storeNav = UseFavoriteFlats();


const route = useRoute();
const router = useRouter();

let props = defineProps(
    {
      openNav: {
        required: false,
      },
      menu: {
        required: false,
      }
    }
);
const closeBurger = () => {
  storeNav.setNav(false);
};
const store = useMenuStore();
const changeRoute = (item) => {
  let url = item.redirect_url ? item.redirect_url : item.url_slug;

  router.push({
    path: `/${ store.getLang }/${ url }`,
  });
  closeBurger();
};

onMounted(async () => {
  var ret = route.path.replace('/', '').replace('/', '').replace('/', '').slice(2);
  console.log(ret);   //prints: 123
});

let name = route.path.replace('/', '').replace('/', '').replace('/', '').slice(2);

let navigation = [
  {
    name: 'Projects'
  },
  {
    name: 'Media'
  },
  {
    name: 'About Us'
  },
  {
    name: 'Contact'
  }
];
const hideFromMobile = (item) => {
  return window.innerWidth < 1023 && !item.configs?.includes('hide_from_mobile');
};
</script>
<style lang="scss" scoped>
.navigation__main {
  position: fixed;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  z-index: 90;
  pointer-events: none;
}

.navigation__active.navigation__main {
  pointer-events: all;
}

.navigation__bg {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: rgba(0, 0, 0, .5);
  transition: .6s cubic-bezier(0.79, 0.01, 0.15, 0.99);
  opacity: 0;
  transition-delay: 1.2s;
}

.navigation__active .navigation__bg {
  opacity: 1;
  transition-delay: 0s;
}

.navigation {
  width: 665px;
  height: 100%;
  position: absolute;
  right: 40px;
  top: 0px;
  z-index: 5;
}

.navigation__layout {
  position: absolute;
  z-index: 1;
  background: #fff;
  transition: .6s cubic-bezier(0.79, 0.01, 0.15, 0.99);
}

.navigation__layout1 {
  width: 100%;
  top: 0px;
  height: 100%;
  left: 0px;
  transform: scaleY(0);
  transform-origin: top;
  transition-delay: .8s;
}

.navigation__layout2 {
  width: 40px;
  top: 20px;
  height: calc(100% - 220px);
  left: -40px;
  transform: scaleX(0);
  transform-origin: right;
  transition-delay: .4s;
}

.navigation__layout3 {
  width: 20px;
  top: 0px;
  height: calc(100% - 100px);
  right: -20px;
  transform: scaleX(0);
  transform-origin: left;
  transition-delay: .3s;
}

.navigation__layout4 {
  width: 20px;
  top: 0px;
  height: calc(100% - 120px);
  right: -40px;
  transform: scaleX(0);
  transform-origin: left;
  transition-delay: 0s;
}

.navigation__active .navigation__layout1 {
  transition-delay: .2s;
}

.navigation__active .navigation__layout2 {
  transition-delay: .7s;
}

.navigation__active .navigation__layout3 {
  transition-delay: .8s;
}

.navigation__active .navigation__layout4 {
  transition-delay: 1.1s;
}

.navigation__active .navigation__layout {
  transform: scaleX(1) scaleY(1);
}

.navigation__content {
  position: absolute;
  z-index: 10;
  width: 100%;
  top: 0px;
  height: 100%;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.navigation__content-inn {
  display: flex;
  flex-direction: column;
}

.navigation__content-inn a {
  overflow: hidden;
  font-size: 55px;
  line-height: 90px;
  margin: 10px 0px;
  display: flex;
  padding: 7px 0px;
  justify-content: center;
}

.navigation__content-inn a span {
  font-size: inherit;
  line-height: inherit;
  transition: .6s cubic-bezier(0.79, 0.01, 0.15, 0.99);
  transform: translateY(110%);
  position: relative;
}

.navigation__content-inn a span:after {
  content: '';
  position: absolute;
  top: 100%;
  left: 0px;
  width: 100%;
  height: 3px;
  background: #000000;
  transition: .6s cubic-bezier(0.79, 0.01, 0.15, 0.99);
  transform: scaleX(0);
  transform-origin: left;
}

.linkHov {
  position: relative;
}

.linkHov:after {
  content: '';
  position: absolute;
  top: 100%;
  left: 0px;
  width: 100%;
  height: 2px;
  background: #000000;
  transition: .6s cubic-bezier(0.79, 0.01, 0.15, 0.99);
  transform: scaleX(0);
  transform-origin: left;
}

.linkHov:hover:after {
  transform: scaleX(1);
}

.navigation__content-inn a.active span:after,
.navigation__content-inn a:hover span:after {
  transform: scaleX(1);
}

.navigation__content-inn a:nth-child(1) span {
  transition-delay: 0s;
}

.navigation__content-inn a:nth-child(2) span {
  transition-delay: .1s;
}

.navigation__content-inn a:nth-child(3) span {
  transition-delay: .2s;
}

.navigation__content-inn a:nth-child(4) span {
  transition-delay: .3s;
}

.navigation__content-inn a:nth-child(5) span {
  transition-delay: .3s;
}

.navigation__active .navigation__content-inn a:nth-child(1) span {
  transition-delay: .3s;
}

.navigation__active .navigation__content-inn a:nth-child(2) span {
  transition-delay: .4s;
}

.navigation__active .navigation__content-inn a:nth-child(3) span {
  transition-delay: .5s;
}

.navigation__active .navigation__content-inn a:nth-child(4) span {
  transition-delay: .6s;
}

.navigation__active .navigation__content-inn a:nth-child(5) span {
  transition-delay: .7s;
}

.navigation__active .navigation__content-inn a span {
  transform: translateY(0%);
}

.navigation__socs {
  position: absolute;
  left: 0px;
  width: 100%;
  display: flex;
  bottom: 60px;
  justify-content: center;
  padding: 5px 0px;
  overflow: hidden;
}

.navigation__socs a {
  margin: 0px 10px;
}

.navigation__socsInner {
  display: flex;
  transform: translateY(10px);
  transition: .6s cubic-bezier(0.79, 0.01, 0.15, 0.99);
  transition-delay: .4s;
  opacity: 0;
}

.navigation__active .navigation__socsInner {
  transition-delay: .7s;
  transform: translateY(0px);
  opacity: 1;
}

.soc-wrapper {
  display: flex;
}

@media only screen and (min-width: 769px) and (max-height: 630px) {
  .navigation__content-inn a {
    margin: 0px !important;
  }
}

.hideDesk {
  display: none;
}

//Laptop
@media (max-width: 1900px) {

  .navigation__layout2 {
    width: 20px;
    top: 20px;
    left: -20px;
    height: calc(100% - 120px);
  }

  .navigation__layout3 {
    width: 10px;
    height: calc(100% - 50px);
    right: -10px;
  }

  .navigation__layout4 {
    width: 10px;
    height: calc(100% - 70px);
    right: -20px;
  }

  .navigation__content-inn a {
    font-size: 45px;
    line-height: 60px;
    margin: 20px 0px
  }

  .navigation__socs a {
    font-size: 18px;
  }

  .navigation__socs {
    bottom: 40px
  }

  .navigation {
    right: 20px;
  }
}

@media (max-width: 1679px) {
  .navigation {
    width: 600px;
  }
}

//Ipad
@media (max-width: 1279px) {
  .navigation__content-inn a {
    font-size: 34px;
    line-height: 42px;
  }
}

//Mobile
@media (max-width: 767px) {
  .linkHov:hover:after {
    transform: scaleX(0);
  }

  .hideDesk {
    display: inline;
  }

  .navigation {
    width: 100%;
    right: 0px;
  }

  .navigation__socsInner {
    flex-direction: column;
    align-items: center;
  }

  .navigation__socs a {
    margin-bottom: 10px;
  }

  .soc-wrapper {
    margin-top: 20px;
  }
  .soc-wrapper a {
    margin-bottom: 0;
  }


  .navigation__content-inn a {
    font-size: 28px;
    line-height: 38px;
    margin: 10px 0px;
  }

  .navigation__content-inn {
    margin-bottom: 100px;
  }
}</style>
